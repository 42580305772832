import firebase from 'firebase';
const firebaseConfig = {
  apiKey: "AIzaSyCd5Dv7qQMs2rDGmB8VeHzQLe5l80UnOQ4",
  authDomain: "newwaysfinances-5b547.firebaseapp.com",
  databaseURL: "https://newwaysfinances-5b547-default-rtdb.firebaseio.com",
  projectId: "newwaysfinances-5b547",
  storageBucket: "newwaysfinances-5b547.appspot.com",
  messagingSenderId: "580486960410",
  appId: "1:580486960410:web:a476ec3271cd963aa988a3"
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);
firebase.analytics();
firebase.firestore().settings({ timestampsInSnapshots: true })

export const f = firebase;
export const database = firebase.database();
export const storage = firebase.storage();
export const auth = firebase.auth();
export default firebase.firestore()