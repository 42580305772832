import React, { useEffect, useRef, useState, useContext } from 'react'
import { Helmet } from "react-helmet";
import { BrowserRouter, useNavigate, Link, Route, Routes, Switch } from "react-router-dom";
import Nav from '../Components/Nav';
import Sidebar from '../Components/Sidebar';
import { f, database, storage, auth } from "../config";
import db from "../config";
import { GlobalContext } from "../Globalstate";
import firebase from "firebase";
import Swal from 'sweetalert2'
import emailjs from "emailjs-com";
import InvestDeposit from '../Components/InvestDeposit';
import Tradingview from '../Components/Tradingview';


function Withdrawal() {
  const history = useNavigate();
  const [{ userdetails, loggedin, tradingpair, depositinfo }, dispatch] = useContext(GlobalContext);
  const [loading, setloading] = useState(false)
  const [step, setstep] = useState(1)
  const [coin, setcoin] = useState("BITCOIN")
  const [wallet, setwallet] = useState("")
  const [Amount, setAmount] = useState("")



  useEffect(() => {
    if (loggedin) {
      console.log(userdetails);
      console.log(userdetails.email);
      setloading(false)
    } else {
      f.auth().onAuthStateChanged(function (user) {
        if (user) {
          var userid = f.auth().currentUser;
          var userids = userid.uid;
          fetchuserdata(userids);
          setloggedin(true);
        } else {
          setloggedin(false);
          setloading(false)
          history("/");
        }
      });
    }
  }, []);

  const fetchuserdata = async (userid) => {
    var docRef = db.collection("users").doc(userid);
    const fetching = await docRef
      .get()
      .then(function (doc) {
        if (doc.exists) {
          setdetails(doc.data());
          setloading(false)
        } else {
          console.log("No such document!");
          setloading(false)
        }
      })
      .catch(function (error) {
        console.log("Error getting document:", error);
      });
  };

  const setdetails = (data) => {
    dispatch({ type: "setuserdetails", snippet: data });
  };

  const setloggedin = (data) => {
    dispatch({ type: "setloggedin", snippet: data });
  };

  const SetcoinTouse = (no) => {

    if (no == 2) {
      setcoin("Tether")
    }
    if (no == "6") {
      setcoin("Ethereum")
    }
    if (no == "7") {
      setcoin("Bitcoin")
    }
    setstep(2)
  }
  const confirmWitdrawal = async () => {

    if (Amount != "") {
      console.log(Amount)
      console.log(userdetails.balance)
      if (parseFloat(userdetails.balance) < parseFloat(Amount)) {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: "balance not enough",
          footer: '<a href="">Why do I have this issue?</a>'
        })
      } else {
        await updatewithdrawalhistory()
      }

    } else {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: "no Amount selected",
        footer: '<a href="">Why do I have this issue?</a>'
      })
    }
  }


  const updatewithdrawalhistory = async () => {

    if (coin == "" || Amount == "" || wallet == "") {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: "Select wallet, Coin , and Amount to procecced withdrawal",
        footer: '<a href="">Why do I have this issue?</a>'
      })
      return 0
    }
    // console.log(cointype)
    setloading(true)
    const newBal = parseFloat(userdetails.balance); /*- parseFloat(Amount);*/
    const newEarings = parseFloat(userdetails.balance) - parseFloat(Amount);
    var userid = f.auth().currentUser;
    var userids = userid.uid;
    const d1 = new Date();
    // const increment = firebase.firestore.FieldValue.increment(parseInt(Amount));
    var washingtonRef = db.collection("users").doc(userids);
    await washingtonRef.update({
      // Totalwithdrawal: increment,
      Withdrawalhistory: firebase.firestore.FieldValue.arrayUnion({
        amt: Amount,
        date: d1.getTime(),
        mode: "withdrawal",
        wallet: wallet,
        coin: coin,
        status: "pending",

      }),
    });
   
    await updateUserBalance()
    await CreatWithdrawalObj()
   

  };


  const updateUserBalance = async (bal) => {
    const newEarings = parseFloat(userdetails.balance) - parseFloat(Amount);
    var userid = f.auth().currentUser;
    var userids = userid.uid;
    var washingtonRef = await db.collection("users").doc(userids);
    washingtonRef
      .update({
        balance: newEarings,
      })
      .then(function () {
        console.log("Document successfully up2dated!");
        // alert("withdrawal was successful we will get back to you");
        Swal.fire({
          icon: 'success',
          title: 'withdrawal was successful we will get back to you!',
          showConfirmButton: false,
          timer: 1500
        })
        window.location.replace("/dashboard");
      })
      .catch(function (error) {
        // The document probably doesn't exist.
        console.error("Error updating document: ", error);
      });
    // fetchuserdata();
  };
  const CreatWithdrawalObj = async () => {
    var userid = f.auth().currentUser;
    var userids = userid.uid;
    const d1 = new Date();
    let uobj = {
      fullname: userdetails.fullname,
      email: userdetails.email,
      timestamp: firebase.firestore.FieldValue.serverTimestamp(),
      userid: userids,
      info: {
        amt: Amount,
        date: d1.getTime(),
        mode: "withdrawal",
        wallet: wallet,
        coin: coin,
        status: "pending",
      }
    };
    const userdetail1 = await db
      .collection("withdrawals")
      .add(uobj)
      .then(function () {
        console.log("Document successfully written!");
      })
      .catch(function (error) {
        console.error("Error writing document: ", error);
      });
  };
  return (
    <div>
      <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
      <title>Withdraw  </title>
      <div className="wrapper">
        <Nav />
        <div id="loader" className="text-center card" style={{ position: 'fixed', display: 'none', left: 0, right: 0, top: 0, bottom: 0, zIndex: 9999, opacity: '0.855' }}>
          <div className="text-info text-center">
            <img className style={{ position: 'absolute', top: '50%', left: '50%', marginTop: '-40px', marginLeft: '-40px' }} src="../util/logo/logo-icon.png" height="auto" width="60px" />
          </div>
        </div>
        <div className="main-panel">
          <div className="container">
            <div className="bg-primary2 pt-4 pb-5" style={{ marginBottom: '-80px' }}>
              <div className="container text-white py-2">
                <div className="d-flex align-items-center">
                  <div className="mr-3">
                    <h2 className="mt-1">Withdraw</h2>
                  </div>
                  <div className="ml-auto">
                    <a href="/deposit" className="btn btn-success btn-round btn-xs">Deposit</a>
                    <a href="TransferFundsl" className="btn btn-white btn-border btn-round btn-xs">Transfer</a>
                    <a href="wallet.html" className="btn btn-dark btn-round btn-xs">Wallet</a>
                  </div>
                </div>
              </div>
            </div>
            <div className="page-inner mt--5">
              <div className="row ">
                <div className="col-md-12 mt-3">
                  <div className="card skew-shadow">
                    <div className="card-header">
                      <div className="card-head-row card-tools-still-right">
                        <h4 className="card-title">Make Withdrawal</h4>
                      </div>
                    </div>
                    <div className="card-body px-0">
                      <form className="forms-sample" >
                        <div className="form-group">
                          <label className="form-control-label">Enter Amount: <span className="text-danger">*</span></label>
                          <input type="number" name="amount" className="form-control" onChange={(e)=>{setAmount(e.target.value)}} />
                        </div>
                        {/* <div className="modal fade" id="modaldemo6" tabIndex={-1} role="dialog" aria-labelledby="trns" aria-hidden="true"> */}
                          {/* <div className="modal-dialog modal-dialog-centered" role="document">
                            <div className="card modal-content">
                              <div className="modal-body text-center"> */}
                                <div className="form-group">
                                  <label htmlFor="recipient-name" className="col-form-label">Choose Wallet Type <span className="text-danger">*</span></label>
                                  <select name="category" className="form-control" onChange={(e)=>{setcoin(e.target.value)}}>
                                    <option label="Choose Wallet Type" />
                                    <option value="btc">Bitcoin(BTC)</option>
                                    <option value="eth">Ethereum(ETH)</option>
                                    <option value="ltc">Litecoin(LTC)</option>
                                    <option value="xrp">Ripple(XRP)</option>
                                    <option value="usdt">Tether(USDT)</option>
                                    <option value={1}>Account Balance</option>
                                    <option value="bal">Account Balance</option>
                                  </select>
                                </div>
                                <div className="form-group">
                                  <label htmlFor="message-text" className="col-form-label">Enter Your Wallet Address <span className="text-danger">*</span></label>
                                  <input type="text" placeholder="Wallet Address" name="name" onChange={(e)=>{setwallet(e.target.value)}}  className="form-control" />
                                </div>
                                <div className="text-center">
                                  <button className="btn btn-success" type='button' onClick={confirmWitdrawal} name="add_wallet">Make WIthdrawal</button>
                                  <button type="button" className="btn btn-outline-warning" data-dismiss="modal" aria-label="Close">Cancel</button>
                                </div>
                              {/* </div>
                            </div>
                          </div> */}
                        {/* </div> */}
                        </form>
                      {/* <div className="form-group text-center">
                        <p><small className="text-primary">Please add a wallet address where to receive your withdrawal </small></p>
                        <a href="#" className="btn btn-outline-primary" data-toggle="modal" data-target="#modaldemo6"><i className="fas fa-wallet mg-r-5" /> Add Wallet</a>
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
              <div className="row ">
                <div className="col-md-12 mt-3">
                  <div className="card">
                    <div className="card-header">
                      <div className="card-head-row card-tools-still-right">
                        <h4 className="card-title">Withdrawal History</h4>
                        <div className="card-tools">
                          <a href="WithdrawalHistory" className="btn btn-primary btn-xs">All Withdrawals</a>
                        </div>
                      </div>
                    </div>
                    <div className="card-body px-0">
                      <div className="table-responsive table-hover table-sales">
                        <table className="table table-striped">
                          <tbody>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
<Tradingview/>
              <h4 className="page-title text-primary">Hot Pricing Plans<span className="float-right">
                <a href="/Myplans" className="btn btn-primary btn-border btn-xs text-white">View All Plans</a>
              </span></h4>
  <InvestDeposit/>
            </div>
          </div>
          <footer className="footer">
            <div className="container-fluid">
              <div className="language langBox" style={{}}>
                <img className="img-fluid mb-0" src="../images/icons/lang-b.png" alt="" style={{ height: '50px', width: 'auto' }} />
                <div id="google_translate_element" />
              </div>
              <nav className="pull-left">
                <ul className="nav">
                  <li className="nav-item">
                    <a className="nav-link" href="support.html">
                      Contact Support
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="Refferals">
                      My Referral
                    </a>
                  </li>
                </ul>
              </nav>
              <div className="copyright ml-auto">
                2023 made with <a href="home.html">Newwaysfinances</a>
              </div>
            </div>
          </footer>
        </div>
      </div>
      <div className="watsPanel">
        <a href="#langDiv">
          <img className="img-fluid mb-0" src="../images/icons/lang.png" alt="" />
        </a>
        <hr className="m-2" />
        <a href="http://wa.me/newell" id="heheh">
          <img className="img-fluid" src="../images/icons/whatsapp.png" alt="" />
        </a>
        <a href="http://wa.me/newell">
          <img className="img-fluid" src="../images/icons/whatsapp-2.png" alt="" />
        </a>
      </div>
      {/* jQuery UI */}
      {/* jQuery Scrollbar */}
      {/* Moment JS */}
      {/* Chart JS */}
      {/* jQuery Sparkline */}
      {/* Chart Circle */}
      {/* Datatables */}
      {/* Bootstrap Notify */}
      {/* Bootstrap Toggle */}
      {/* jQuery Vector Maps */}
      {/* Google Maps Plugin */}
      {/* Dropzone */}
      {/* Fullcalendar */}
      {/* DateTimePicker */}
      {/* Bootstrap Tagsinput */}
      {/* Bootstrap Wizard */}
      {/* jQuery Validation */}
      {/* Summernote */}
      {/* Select2 */}
      {/* Sweet Alert */}
      {/* Owl Carousel */}
      {/* Magnific Popup */}
      {/* Atlantis JS */}
      {/* Code provided by Google */}
      {/* End custom js for this page */}
    </div>
  )
}

export default Withdrawal