import React, { useEffect, useRef, useState, useContext } from 'react'
import { Helmet } from "react-helmet";
import { BrowserRouter, useNavigate, Link, Route, Routes, Switch } from "react-router-dom";
import Nav from '../Components/Nav';
import InvestDeposit from '../Components/InvestDeposit';
import Sidebar from '../Components/Sidebar';
import { f, database, storage, auth } from "../config";
import db from "../config";
import { GlobalContext } from "../Globalstate";
import firebase from "firebase";
import Swal from 'sweetalert2'
import Tradingview from '../Components/Tradingview';


function Profile() {
  const history = useNavigate();
  const [{ userdetails, loggedin, tradingpair, depositinfo }, dispatch] = useContext(GlobalContext);
  const [loading, setloading] = useState(false)

  const [password, setpassword] = useState("")
  const [password2, setpassword2] = useState("")
  const [fullname, setfullname] = useState("")
  const [address, setaddress] = useState("")
  const [phonenumber, setphonenumber] = useState("")
  const [dateOfBirth, setdateOfBirth] = useState("")
  const [country, setcountry] = useState("")


  useEffect(() => {
    if (loggedin) {
      console.log(userdetails);
      console.log(userdetails.email);
      setloading(false)
    } else {
      f.auth().onAuthStateChanged(function (user) {
        if (user) {
          var userid = f.auth().currentUser;
          var userids = userid.uid;
          fetchuserdata(userids);
          setloggedin(true);
        } else {
          setloggedin(false);
          setloading(false)
          history("/");
        }
      });
    }
  }, []);

  const fetchuserdata = async (userid) => {
    var docRef = db.collection("users").doc(userid);
    const fetching = await docRef
      .get()
      .then(function (doc) {
        if (doc.exists) {
          setdetails(doc.data());
          setfullname(doc.data().fullname)
          setaddress(doc.data().Address)
          setphonenumber(doc.data().Phoneno)
          //  setdateOfBirth(doc.data().DateOfBirth)
          // Phoneno:phonenumber,
          // Address:address,
          // fullname:fullname,
          // DateOfBirth:dateOfBirth,
          setloading(false)
        } else {
          console.log("No such document!");
          setloading(false)
        }
      })
      .catch(function (error) {
        console.log("Error getting document:", error);
      });
  };

  const setdetails = (data) => {
    dispatch({ type: "setuserdetails", snippet: data });
  };

  const setloggedin = (data) => {
    dispatch({ type: "setloggedin", snippet: data });
  };



  const updateUserProfile = async () => {

    let userid = f.auth().currentUser;
    let userids = userid.uid;
    var docRef = db.collection("users").doc(userids);
    console.log(phonenumber, address, fullname, dateOfBirth)
    console.log(fullname)
    const update = await docRef
      .update({
        Phoneno: phonenumber,
        fullname: fullname,
        address: address
      })
      .then(function () {
        console.log("Document successfully updated!");
        Swal.fire(
          'Update Complete!',
          'Your account has been updataed.',
          'success'
        )
      })
      .catch(function (error) {
        console.error("Error updating document: ", error);
      });
  };


  const changepassword = () => {

    if (password == "") {
      Swal.fire(
        'No Selected Passowrd?',
        'Please Select A Valid Password',
      )
      return

    }

    if (password != password2) {
      Swal.fire(
        'Password does not match?',
        'Password must match',
      )
      return
    }
    setloading(true)
    var user = f.auth().currentUser;
    user
      .updatePassword(password)
      .then(function () {
        setloading(false)
        Swal.fire({
          icon: 'success',
          title: 'password changed',
          showConfirmButton: false,
          timer: 1500
        })
      })
      .catch(function (error) {
        console.log(error)
        setloading(false)
        Swal.fire(
          'Something went wrong',
          `${error.message}`,
        )

      });
  };

  const save = () => {
    Swal.fire({
      icon: 'success',
      title: 'Account has been updated successfully',
      showConfirmButton: false,
      timer: 1500
    })
  }
  return (
    <div>
    <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
    <title>My Profile  </title>
    <div className="wrapper">
        <Nav />          
      <div id="loader" className="text-center card" style={{position: 'fixed', display: 'none', left: 0, right: 0, top: 0, bottom: 0, zIndex: 9999, opacity: '0.855'}}>
        <div className="text-info text-center">
          <img className style={{position: 'absolute', top: '50%', left: '50%', marginTop: '-40px', marginLeft: '-40px'}} src="../util/logo/logo-icon.png" height="auto" width="60px" />
        </div>
      </div>
      <div className="main-panel">
        <div className="container">
          <div className="bg-primary2 pt-4 pb-5" style={{marginBottom: '-80px'}}>
            <div className="container text-white py-2">
              <div className="d-flex align-items-center">
                <div className="mr-3">
                  <h2 className="mt-1">My Profile</h2>
                </div>
                <div className="ml-auto">
                </div>
              </div>
            </div>
          </div>                            
          <div className="page-inner mt--5">
            <div className="row ">
              <div className="col-md-12 mt-3">
                <div className="card">
                  <div className="card-header">
                    <div className="card-head-row card-tools-still-right">
                      <h4 className="card-title">Edit Profile</h4>
                    </div>
                  </div>
                  <div className="card-body px-0">
                    <div className="row">
                      <div className="col-md-8 mb-2">
                        <div className="card">
                          <div className="card-body">
                            <form className="forms-sample" >
                              <div className="form-group">
                                <h3 className="text-primary">Update Contact Information</h3>
                              </div>
                              <div className="form-group">
                                <label className="form-control-label">Username(Not editable): <span className="tx-danger" /></label>
                                <input type="text" className="form-control" defaultValue={fullname} />
                              </div>
                              <div className="form-group">
                                <label className="form-control-label">Email: <span className="tx-danger">*</span></label>
                                <input type="email" disabled defaultValue="oandafinancegroup@gmail.com" name="email" className="form-control" />
                              </div>
                              <div className="form-group">
                                <label className="form-control-label">Country: <span className="tx-danger">*</span></label>
                                <select name="country" className="form-control">
                                  <option value="Country">Country</option>
                                  <option value="Afganistan">Afghanistan</option>
                                  <option value="Albania">Albania</option>
                                  <option value="Algeria">Algeria</option>
                                  <option value="American Samoa">American Samoa</option>
                                  <option value="Andorra">Andorra</option>
                                  <option value="Angola">Angola</option>
                                  <option value="Anguilla">Anguilla</option>
                                  <option value="Antigua & Barbuda">Antigua &amp; Barbuda</option>
                                  <option value="Argentina">Argentina</option>
                                  <option value="Armenia">Armenia</option>
                                  <option value="Aruba">Aruba</option>
                                  <option value="Australia">Australia</option>
                                  <option value="Austria">Austria</option>
                                  <option value="Azerbaijan">Azerbaijan</option>
                                  <option value="Bahamas">Bahamas</option>
                                  <option value="Bahrain">Bahrain</option>
                                  <option value="Bangladesh">Bangladesh</option>
                                  <option value="Barbados">Barbados</option>
                                  <option value="Belarus">Belarus</option>
                                  <option value="Belgium">Belgium</option>
                                  <option value="Belize">Belize</option>
                                  <option value="Benin">Benin</option>
                                  <option value="Bermuda">Bermuda</option>
                                  <option value="Bhutan">Bhutan</option>
                                  <option value="Bolivia">Bolivia</option>
                                  <option value="Bonaire">Bonaire</option>
                                  <option value="Bosnia & Herzegovina">Bosnia &amp; Herzegovina</option>
                                  <option value="Botswana">Botswana</option>
                                  <option value="Brazil">Brazil</option>
                                  <option value="British Indian Ocean Ter">British Indian Ocean Ter</option>
                                  <option value="Brunei">Brunei</option>
                                  <option value="Bulgaria">Bulgaria</option>
                                  <option value="Burkina Faso">Burkina Faso</option>
                                  <option value="Burundi">Burundi</option>
                                  <option value="Cambodia">Cambodia</option>
                                  <option value="Cameroon">Cameroon</option>
                                  <option value="Canada">Canada</option>
                                  <option value="Canary Islands">Canary Islands</option>
                                  <option value="Cape Verde">Cape Verde</option>
                                  <option value="Cayman Islands">Cayman Islands</option>
                                  <option value="Central African Republic">Central African Republic</option>
                                  <option value="Chad">Chad</option>
                                  <option value="Channel Islands">Channel Islands</option>
                                  <option value="Chile">Chile</option>
                                  <option value="China">China</option>
                                  <option value="Christmas Island">Christmas Island</option>
                                  <option value="Cocos Island">Cocos Island</option>
                                  <option value="Colombia">Colombia</option>
                                  <option value="Comoros">Comoros</option>
                                  <option value="Congo">Congo</option>
                                  <option value="Cook Islands">Cook Islands</option>
                                  <option value="Costa Rica">Costa Rica</option>
                                  <option value="Cote DIvoire">Cote DIvoire</option>
                                  <option value="Croatia">Croatia</option>
                                  <option value="Cuba">Cuba</option>
                                  <option value="Curaco">Curacao</option>
                                  <option value="Cyprus">Cyprus</option>
                                  <option value="Czech Republic">Czech Republic</option>
                                  <option value="Denmark">Denmark</option>
                                  <option value="Djibouti">Djibouti</option>
                                  <option value="Dominica">Dominica</option>
                                  <option value="Dominican Republic">Dominican Republic</option>
                                  <option value="East Timor">East Timor</option>
                                  <option value="Ecuador">Ecuador</option>
                                  <option value="Egypt">Egypt</option>
                                  <option value="El Salvador">El Salvador</option>
                                  <option value="Equatorial Guinea">Equatorial Guinea</option>
                                  <option value="Eritrea">Eritrea</option>
                                  <option value="Estonia">Estonia</option>
                                  <option value="Ethiopia">Ethiopia</option>
                                  <option value="Falkland Islands">Falkland Islands</option>
                                  <option value="Faroe Islands">Faroe Islands</option>
                                  <option value="Fiji">Fiji</option>
                                  <option value="Finland">Finland</option>
                                  <option value="France">France</option>
                                  <option value="French Guiana">French Guiana</option>
                                  <option value="French Polynesia">French Polynesia</option>
                                  <option value="French Southern Ter">French Southern Ter</option>
                                  <option value="Gabon">Gabon</option>
                                  <option value="Gambia">Gambia</option>
                                  <option value="Georgia">Georgia</option>
                                  <option value="Germany">Germany</option>
                                  <option value="Ghana">Ghana</option>
                                  <option value="Gibraltar">Gibraltar</option>
                                  <option value="Great Britain">Great Britain</option>
                                  <option value="Greece">Greece</option>
                                  <option value="Greenland">Greenland</option>
                                  <option value="Grenada">Grenada</option>
                                  <option value="Guadeloupe">Guadeloupe</option>
                                  <option value="Guam">Guam</option>
                                  <option value="Guatemala">Guatemala</option>
                                  <option value="Guinea">Guinea</option>
                                  <option value="Guyana">Guyana</option>
                                  <option value="Haiti">Haiti</option>
                                  <option value="Hawaii">Hawaii</option>
                                  <option value="Honduras">Honduras</option>
                                  <option value="Hong Kong">Hong Kong</option>
                                  <option value="Hungary">Hungary</option>
                                  <option value="Iceland">Iceland</option>
                                  <option value="Indonesia">Indonesia</option>
                                  <option value="India">India</option>
                                  <option value="Iran">Iran</option>
                                  <option value="Iraq">Iraq</option>
                                  <option value="Ireland">Ireland</option>
                                  <option value="Isle of Man">Isle of Man</option>
                                  <option value="Israel">Israel</option>
                                  <option value="Italy">Italy</option>
                                  <option value="Jamaica">Jamaica</option>
                                  <option value="Japan">Japan</option>
                                  <option value="Jordan">Jordan</option>
                                  <option value="Kazakhstan">Kazakhstan</option>
                                  <option value="Kenya">Kenya</option>
                                  <option value="Kiribati">Kiribati</option>
                                  <option value="Korea North">Korea North</option>
                                  <option value="Korea Sout">Korea South</option>
                                  <option value="Kuwait">Kuwait</option>
                                  <option value="Kyrgyzstan">Kyrgyzstan</option>
                                  <option value="Laos">Laos</option>
                                  <option value="Latvia">Latvia</option>
                                  <option value="Lebanon">Lebanon</option>
                                  <option value="Lesotho">Lesotho</option>
                                  <option value="Liberia">Liberia</option>
                                  <option value="Libya">Libya</option>
                                  <option value="Liechtenstein">Liechtenstein</option>
                                  <option value="Lithuania">Lithuania</option>
                                  <option value="Luxembourg">Luxembourg</option>
                                  <option value="Macau">Macau</option>
                                  <option value="Macedonia">Macedonia</option>
                                  <option value="Madagascar">Madagascar</option>
                                  <option value="Malaysia">Malaysia</option>
                                  <option value="Malawi">Malawi</option>
                                  <option value="Maldives">Maldives</option>
                                  <option value="Mali">Mali</option>
                                  <option value="Malta">Malta</option>
                                  <option value="Marshall Islands">Marshall Islands</option>
                                  <option value="Martinique">Martinique</option>
                                  <option value="Mauritania">Mauritania</option>
                                  <option value="Mauritius">Mauritius</option>
                                  <option value="Mayotte">Mayotte</option>
                                  <option value="Mexico">Mexico</option>
                                  <option value="Midway Islands">Midway Islands</option>
                                  <option value="Moldova">Moldova</option>
                                  <option value="Monaco">Monaco</option>
                                  <option value="Mongolia">Mongolia</option>
                                  <option value="Montserrat">Montserrat</option>
                                  <option value="Morocco">Morocco</option>
                                  <option value="Mozambique">Mozambique</option>
                                  <option value="Myanmar">Myanmar</option>
                                  <option value="Nambia">Nambia</option>
                                  <option value="Nauru">Nauru</option>
                                  <option value="Nepal">Nepal</option>
                                  <option value="Netherland Antilles">Netherland Antilles</option>
                                  <option value="Netherlands">Netherlands (Holland, Europe)</option>
                                  <option value="Nevis">Nevis</option>
                                  <option value="New Caledonia">New Caledonia</option>
                                  <option value="New Zealand">New Zealand</option>
                                  <option value="Nicaragua">Nicaragua</option>
                                  <option value="Niger">Niger</option>
                                  <option value="Nigeria">Nigeria</option>
                                  <option value="Niue">Niue</option>
                                  <option value="Norfolk Island">Norfolk Island</option>
                                  <option value="Norway">Norway</option>
                                  <option value="Oman">Oman</option>
                                  <option value="Pakistan">Pakistan</option>
                                  <option value="Palau Island">Palau Island</option>
                                  <option value="Palestine">Palestine</option>
                                  <option value="Panama">Panama</option>
                                  <option value="Papua New Guinea">Papua New Guinea</option>
                                  <option value="Paraguay">Paraguay</option>
                                  <option value="Peru">Peru</option>
                                  <option value="Phillipines">Philippines</option>
                                  <option value="Pitcairn Island">Pitcairn Island</option>
                                  <option value="Poland">Poland</option>
                                  <option value="Portugal">Portugal</option>
                                  <option value="Puerto Rico">Puerto Rico</option>
                                  <option value="Qatar">Qatar</option>
                                  <option value="Republic of Montenegro">Republic of Montenegro</option>
                                  <option value="Republic of Serbia">Republic of Serbia</option>
                                  <option value="Reunion">Reunion</option>
                                  <option value="Romania">Romania</option>
                                  <option value="Russia">Russia</option>
                                  <option value="Rwanda">Rwanda</option>
                                  <option value="St Barthelemy">St Barthelemy</option>
                                  <option value="St Eustatius">St Eustatius</option>
                                  <option value="St Helena">St Helena</option>
                                  <option value="St Kitts-Nevis">St Kitts-Nevis</option>
                                  <option value="St Lucia">St Lucia</option>
                                  <option value="St Maarten">St Maarten</option>
                                  <option value="St Pierre & Miquelon">St Pierre &amp; Miquelon</option>
                                  <option value="St Vincent & Grenadines">St Vincent &amp; Grenadines</option>
                                  <option value="Saipan">Saipan</option>
                                  <option value="Samoa">Samoa</option>
                                  <option value="Samoa American">Samoa American</option>
                                  <option value="San Marino">San Marino</option>
                                  <option value="Sao Tome & Principe">Sao Tome &amp; Principe</option>
                                  <option value="Saudi Arabia">Saudi Arabia</option>
                                  <option value="Senegal">Senegal</option>
                                  <option value="Seychelles">Seychelles</option>
                                  <option value="Sierra Leone">Sierra Leone</option>
                                  <option value="Singapore">Singapore</option>
                                  <option value="Slovakia">Slovakia</option>
                                  <option value="Slovenia">Slovenia</option>
                                  <option value="Solomon Islands">Solomon Islands</option>
                                  <option value="Somalia">Somalia</option>
                                  <option value="South Africa">South Africa</option>
                                  <option value="Spain">Spain</option>
                                  <option value="Sri Lanka">Sri Lanka</option>
                                  <option value="Sudan">Sudan</option>
                                  <option value="Suriname">Suriname</option>
                                  <option value="Swaziland">Swaziland</option>
                                  <option value="Sweden">Sweden</option>
                                  <option value="Switzerland">Switzerland</option>
                                  <option value="Syria">Syria</option>
                                  <option value="Tahiti">Tahiti</option>
                                  <option value="Taiwan">Taiwan</option>
                                  <option value="Tajikistan">Tajikistan</option>
                                  <option value="Tanzania">Tanzania</option>
                                  <option value="Thailand">Thailand</option>
                                  <option value="Togo">Togo</option>
                                  <option value="Tokelau">Tokelau</option>
                                  <option value="Tonga">Tonga</option>
                                  <option value="Trinidad & Tobago">Trinidad &amp; Tobago</option>
                                  <option value="Tunisia">Tunisia</option>
                                  <option value="Turkey">Turkey</option>
                                  <option value="Turkmenistan">Turkmenistan</option>
                                  <option value="Turks & Caicos Is">Turks &amp; Caicos Is</option>
                                  <option value="Tuvalu">Tuvalu</option>
                                  <option value="Uganda">Uganda</option>
                                  <option value="United Kingdom">United Kingdom</option>
                                  <option value="Ukraine">Ukraine</option>
                                  <option value="United Arab Erimates">United Arab Emirates</option>
                                  <option value="United States of America">United States of America</option>
                                  <option value="Uraguay">Uruguay</option>
                                  <option value="Uzbekistan">Uzbekistan</option>
                                  <option value="Vanuatu">Vanuatu</option>
                                  <option value="Vatican City State">Vatican City State</option>
                                  <option value="Venezuela">Venezuela</option>
                                  <option value="Vietnam">Vietnam</option>
                                  <option value="Virgin Islands (Brit)">Virgin Islands (Brit)</option>
                                  <option value="Virgin Islands (USA)">Virgin Islands (USA)</option>
                                  <option value="Wake Island">Wake Island</option>
                                  <option value="Wallis & Futana Is">Wallis &amp; Futana Is</option>
                                  <option value="Yemen">Yemen</option>
                                  <option value="Zaire">Zaire</option>
                                  <option value="Zambia">Zambia</option>
                                  <option value="Zimbabwe">Zimbabwe</option>
                                </select>
                              </div>
                              <div className="form-group">
                                <button className="btn btn-success" name="update_info" type="button" onClick={save}>Update Contact Information</button>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4 mb-2">
                        <div className="card">
                          <div className="card-body">
                            <form className action method="post">
                              <div className="form-group">
                                <h3 className="text-primary">Update Password</h3>
                              </div>
                              <div className="form-group">
                                <label className="form-control-label">Old Password: <span className="tx-danger">*</span></label>
                                <input type="password" name="password" className="form-control" placeholder="Enter Old Password" />
                              </div>
                              <div className="form-group">
                                <label className="form-control-label">New Password: <span className="tx-danger">*</span></label>
                                <input type="password" name="new_password" className="form-control" placeholder="Enter New Password"  onChange={(e)=>{setpassword(e.target.value)}}/>
                              </div>
                              <div className="form-group">
                                <label className="form-control-label">Repeat New Password: <span className="tx-danger">*</span></label>
                                <input type="password" name="new_password_retype" className="form-control" placeholder="Repeat New Password" onChange={(e)=>{setpassword2(e.target.value)}} />
                              </div>
                              <div className="form-group">
                                <button className="btn btn-success" name="update_password" type="button" onClick={changepassword}>Update Password</button>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
<Tradingview/>
            <h4 className="page-title text-primary">Hot Pricing Plans<span className="float-right">
                <a href="/Myplans" className="btn btn-primary btn-border btn-xs text-white">View All Plans</a>
              </span></h4>
<InvestDeposit/>	
          </div>
        </div>
        <footer className="footer">
          <div className="container-fluid">
            <div className="language langBox" style={{}}>
              <img className="img-fluid mb-0" src="../images/icons/lang-b.png" alt="" style={{height: '50px', width: 'auto'}} />
              <div id="google_translate_element" />
            </div>
            <nav className="pull-left">
              <ul className="nav">
                <li className="nav-item">
                  <a className="nav-link" href="support.html">
                    Contact Support
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="Refferals">
                    My Referral
                  </a>
                </li>
              </ul>
            </nav>
            <div className="copyright ml-auto">
              2023 made with <a href="home.html">Newwaysfinances</a>
            </div>				
          </div>
        </footer>                    
      </div>
    </div>
    <div className="watsPanel">
      <a href="#langDiv">
        <img className="img-fluid mb-0" src="../images/icons/lang.png" alt="" />
      </a>
      <hr className="m-2" />
      <a href="http://wa.me/newell" id="heheh">
        <img className="img-fluid" src="../images/icons/whatsapp.png" alt="" />
      </a>
      <a href="http://wa.me/newell">
        <img className="img-fluid" src="../images/icons/whatsapp-2.png" alt="" />
      </a>
    </div>
    {/* jQuery UI */}
    {/* jQuery Scrollbar */}
    {/* Moment JS */}
    {/* Chart JS */}
    {/* jQuery Sparkline */}
    {/* Chart Circle */}
    {/* Datatables */}
    {/* Bootstrap Notify */}
    {/* Bootstrap Toggle */}
    {/* jQuery Vector Maps */}
    {/* Google Maps Plugin */}
    {/* Dropzone */}
    {/* Fullcalendar */}
    {/* DateTimePicker */}
    {/* Bootstrap Tagsinput */}
    {/* Bootstrap Wizard */}
    {/* jQuery Validation */}
    {/* Summernote */}
    {/* Select2 */}
    {/* Sweet Alert */}
    {/* Owl Carousel */}
    {/* Magnific Popup */}
    {/* Atlantis JS */}
    {/* Code provided by Google */}
    {/* End custom js for this page */}
  </div>
  )
}

export default Profile