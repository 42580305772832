import React, { useState } from 'react';

// Define an array of investment plans


const InvestDeposit = () => {

    const investmentPlans = [
        {
            title: 'CLASSIC',
            minDeposit: 100.0,
            maxDeposit: 999.0,
            dailyProfit: 2.5,
            referralBonus: 3,
            planDuration: 4,
            profitReturn: 10,
            radio: 80
        },
        {
            title: 'DELUXE',
            minDeposit: 1000.0,
            maxDeposit: 9999.0,
            dailyProfit: 3.5,
            referralBonus: 5,
            planDuration: 6,
            profitReturn: 21,
            radio: 81
        },
        {
            title: 'PLATINUM',
            minDeposit: 10000.0,
            maxDeposit: 19999.0,
            dailyProfit: 5,
            referralBonus: 6,
            planDuration: 8,
            profitReturn: 40,
            radio: 82
        },
        {
            title: 'VIP (LONG TERM)',
            minDeposit: 20000.0,
            maxDeposit: 1000000.0,
            dailyProfit: 5.5,
            referralBonus: 10,
            planDuration: 30,
            profitReturn: 165,
            radio: 83
        },
    ];

    const ListOfCoins = [

    ]
    const [Amount, setAmount] = useState(0)
    const [coinToUse, setcoinToUse] = useState(0)
    const [setselectedinvet, setsetselectedinvet] = useState(0)

  

    const processToInvestment = (investment) => {

        if(Amount != 0 && coinToUse != 0){

            const baseUrl = '/CompleteDeposit';
            const params = new URLSearchParams();
            params.append('Invetmentplan', investment);
            params.append('Coin', coinToUse);
            params.append('amount', Amount);
            const completeDepositUrl = baseUrl + '?' + params.toString();
    
            global.window && (global.window.location.href = completeDepositUrl)
        }else{
         alert("Fill IN THE REQUIRED FIELDS")
        }

    }

    return (
        <div className="row justify-content-center align-items-center">
            {investmentPlans.map((plan, index) => (
                <div className="col-md-3">
                    <div className="card-pricing2 card-primary">
                        <div className="pricing-header">
                            <h3 className="fw-bold">{plan.title}</h3>
                            <span className=" badge badge-light">Investment Plans</span>
                        </div>
                        <div className="price-value">
                            <div className="value">
                                <span className="currency" />
                                <span className="amount">{plan.dailyProfit}<span>%</span></span>
                                <span className="month">/Daily</span>
                            </div>
                        </div>
                        <ul className="pricing-content">
                            <li>Min Deposit <b className="float-right">${plan.minDeposit}</b></li>
                            <li>Max Deposit <b className="float-right">${plan.maxDeposit}</b></li>
                            <li>Daily Profit <b className="float-right">{plan.dailyProfit}%</b></li>
                            <li>Referral Bonus <b className="float-right">{plan.referralBonus}%</b></li>
                            <li>Plan Duration <b className="float-right">{plan.planDuration} Day(s)</b></li>
                            <li>Profit Return <b>{plan.profitReturn}% After {plan.planDuration} Day(s)</b></li>
                        </ul>
                        <form className="forms-sample" action="deposit.html" method="post">
                            <div className="form-group">
                                <label className="form-control-label">Enter Amount: <span className="text-danger">*</span></label>
                                <input type="number" name="amount" className="form-control" onChange={(e) => { setAmount(e.target.value) }} />
                            </div>
                            <input type="hidden" name="plan" defaultValue={plan.radio} />
                            <div className="form-group">
                                <label className="form-control-label">Choose Payment Method: <span className="text-danger">*</span></label>
                                <div className="row">
                                    <div className="col-6" onClick={() => { setcoinToUse("BTC") }}>
                                        <input type="radio" className="myRadio" id={`myRadio1` + plan.radio} name="walletType" defaultValue="btc" />
                                        <label htmlFor={`myRadio1` + plan.radio} className="myLabel"><img src="../images/coins/s-btc.png" /></label>
                                    </div>
                                    <div className="col-6" onClick={() => { setcoinToUse("ETH") }}>
                                        <input type="radio" className="myRadio" id={`myRadio2` + plan.radio} name="walletType" defaultValue="eth" />
                                        <label htmlFor={`myRadio2` + plan.radio} className="myLabel"><img src="../images/coins/s-eth.png" /></label>
                                    </div>
                                    <div className="col-6" onClick={() => { setcoinToUse("LTC") }}>
                                        <input type="radio" className="myRadio" id={`myRadio3` + plan.radio} name="walletType" defaultValue="ltc" />
                                        <label htmlFor={`myRadio3` + plan.radio} className="myLabel"><img src="../images/coins/s-ltc.png" /></label>
                                    </div>
                                    <div className="col-6" onClick={() => { setcoinToUse("USDT ERC20") }}>
                                        <input type="radio" className="myRadio" id={`myRadio4` + plan.radio} name="walletType" defaultValue="xrp" />
                                        <label htmlFor={`myRadio4` + plan.radio} className="myLabel"><img src="../images/coins/s-usdt.png" /></label>
                                    </div>
                                    <div className="col-6" onClick={() => { setcoinToUse("USDT Trc20") }}>
                                        <input type="radio" className="myRadio" id={`myRadio5` + plan.radio} name="walletType" defaultValue="usdt" />
                                        <label htmlFor={`myRadio5` + plan.radio} className="myLabel"><img src="../images/coins/s-usdt.png" /></label>
                                    </div>
                                    <div className="col-6" onClick={() => { setcoinToUse("WALLET") }}>
                                        <input type="radio" className="myRadio" id={`myRadio6` + plan.radio} name="walletType" defaultValue={1} />
                                        <label htmlFor={`myRadio6` + plan.radio} className="myLabel"><img src="../images/coins/s-bal.png" /></label>
                                    </div>
                                </div>
                            </div>
                            <button className="btn btn-primary btn-lg w-75 fw-bold mb-3" name="invest" type="button" onClick={() => { processToInvestment(plan.title) }}>Make Deposit</button>
                        </form>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default InvestDeposit;
